// スマホサイズでサイドバーを表示した際に、サイドバーが表示される領域と重なっている部分が反応するように
// サイドバー表示時にclass属性を追加/追加されていれば除去
document.addEventListener('turbolinks:load', () => {
  const sidebar = document.querySelector('.main-sidebar');

  sidebar.addEventListener('click', () => {
    sidebar.classList.toggle('open');
  });
});

// ハンバーガーメニュー、サイドバーを制御
function initializeSidebar() {
  const pushMenuButton = document.querySelector('[data-widget="pushmenu"]');
  const mainSidebar = document.querySelector('.main-sidebar');

  const toggleSidebar = (show) => {
    // スマホ画面では画面遷移後の1度目のボタン押下が反応しなかったため、PCと処理を分離
    const isMobile = window.innerWidth < 992;
    if (isMobile) {
      // スマホ
      if (show) {
        document.body.classList.add('sidebar-open');
        document.body.classList.remove('sidebar-collapse');
      } else {
        document.body.classList.remove('sidebar-open');
        document.body.classList.add('sidebar-collapse');
      }
    } else {
      // PC
      const isInitialLoad = !document.body.classList.contains('sidebar-collapse') &&
                              !document.body.classList.contains('sidebar-open');

      if (isInitialLoad) {
        document.body.classList.add('sidebar-collapse');
        document.body.classList.remove('sidebar-open');
      } else if (show) {
        document.body.classList.remove('sidebar-collapse');
        document.body.classList.add('sidebar-open');
      } else {
        document.body.classList.add('sidebar-collapse');
        document.body.classList.remove('sidebar-open');
      }
    }
  };

  // 画面サイズに応じた初期状態の設定
  const initializeState = () => {
    const isMobile = window.innerWidth < 992;
    if (isMobile) {
      document.body.classList.remove('sidebar-open');
      document.body.classList.add('sidebar-collapse');
    }
  };

  // 初期状態を設定
  initializeState();

  // ハンバーガーメニューの設定
  if (pushMenuButton) {
    const newPushMenuButton = pushMenuButton.cloneNode(true);
    pushMenuButton.parentNode.replaceChild(newPushMenuButton, pushMenuButton);

    newPushMenuButton.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      const currentState = document.body.classList.contains('sidebar-open');
      toggleSidebar(!currentState);
    });
  }

  if (mainSidebar) {
    mainSidebar.classList.add('sidebar-transitioning');

    // 閉じるボタンを作成
    let closeBtn = mainSidebar.querySelector('.sidebar-close-btn');
    if (!closeBtn) {
      closeBtn = document.createElement('button');
      closeBtn.className = 'sidebar-close-btn';
      closeBtn.setAttribute('aria-label', 'Close sidebar');
      mainSidebar.appendChild(closeBtn);

      // 閉じるボタンの開閉
      closeBtn.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleSidebar(false);
      });
    }
  }

  // オーバーレイの設定
  let overlay = document.getElementById('sidebar-overlay');
  if (!overlay) {
    overlay = document.createElement('div');
    overlay.id = 'sidebar-overlay';
    document.body.appendChild(overlay);

    overlay.addEventListener('click', () => {
      toggleSidebar(false);
    });
  }
}

// 両方のイベントで初期化を実行
document.addEventListener('DOMContentLoaded', initializeSidebar);
document.addEventListener('turbolinks:load', initializeSidebar);
